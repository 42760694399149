.App {
  display: flex;
}

.flex,
.page,
.row,
.column {
  display: flex;
}

.page,
.column {
  flex-direction: column;
}

.row-gap {
  row-gap: 12px;
}

.column-gap {
  column-gap: 12px;
}
